export default function useStatus() {
    const statuses = [
        { name: 'En progreso', value: 'en_progreso' },
        { name: 'Terminado', value: 'terminado' },
    ]

    const checkStatus = (status) => {
        switch (status) {
            case "aceptada":
            case "aceptado":
            case "atendido":
            case "atendida":
            case "pagada":
            case "cumplido":
            case "terminado":
            case "terminada":
                return {
                    text: capitalize(status),
                    color: "success",
                };
            case "confirmado":
            case "confirmada":
            case "en_progreso":
                return {
                    text: capitalize(status),
                    color: "warning",
                };
            case "pendiente":
                return {
                    text: capitalize(status),
                    color: "info",
                };
            case "rechazada":
            case "rechazado":
            case "cancelada":
            case "cancelado":
            case "vencido":
                return {
                    text: capitalize(status),
                    color: "danger",
                };
        }
    };

    const updateStatus = async ({ id, status, route_name, item }) => {
        Swal.fire({
            title: "¿Estas seguro?",
            html: `El estado cambiara a <b class="text-${
                checkStatus(status).color
            }">${checkStatus(status).text}</b>`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire("Espera...", "Se esta ejecutando el proceso", "info");
                try {
                    const response = await axios.put(route(route_name), {
                        id: id,
                        status: status,
                    });
                    const data = await response;

                    if (data.status == 200) {
                        Swal.fire(
                            "Hecho",
                            "Registro actualizado correctamente",
                            "success"
                        ).then(() => {
                            if (Array.isArray(item)) {
                                const foundObject = item.find(
                                    (arr) => arr.id == id
                                );
                                foundObject.status = status;
                            } else {
                                item.status = status;
                            }
                        });
                    }
                } catch (err) {
                    Swal.fire(
                        "Error",
                        "No encontramos lo que buscaba!",
                        "error"
                    );
                    console.error(err);
                }
            }
        });
    };

    const updateStatusComments = async (params) => {
        Swal.fire({
            title: "¿Estas seguro?",
            html: `El estado cambiara a <b class="text-${
                checkStatus(params.status).color
            }">${checkStatus(params.status).text}</b>`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (params.status == "terminado") statusComments(params)
                else await serviceUpdateStatus(params)
            }
        });
    };

    const statusComments = async(params) => {
        const { value: comments } = await Swal.fire({
            input: "textarea",
            inputLabel: "Comentarios",
            inputPlaceholder: "Escribe tus comentarios aquí...",
            inputAttributes: {
                "aria-label": "Escribe tus comentarios aquí",
            },
            showCancelButton: true,
        });
        if (comments) await serviceUpdateStatus({...params, comments})
    };

    const serviceUpdateStatus = async({ id, status, route_name, item, comments }) => {
        Swal.fire("Espera...", "Se esta ejecutando el proceso", "info");
        try {
            const response = await axios.put(route(route_name), {
                id: id,
                status: status,
                comments: comments,
            });
            const data = await response;

            if (data.status == 200) {
                Swal.fire(
                    "Hecho",
                    "Registro actualizado correctamente",
                    "success"
                ).then(() => {
                    if (Array.isArray(item)) {
                        const foundObject = item.find(
                            (arr) => arr.id == id
                        );
                        foundObject.status = status;
                    } else {
                        item.status = status;
                        item.comments = comments;
                    }
                });
            }
        } catch (err) {
            Swal.fire("Error", "No encontramos lo que buscaba!", "error");
            console.error(err);
        }
    }

    const capitalize = (word) => {
        const nWord = word.includes("_") ? normalize(word) : word;
        return nWord.charAt(0).toUpperCase() + nWord.slice(1);
    };
    const normalize = (word) => {
        return word.replaceAll("_", " ");
    };

    return {
        statuses,
        checkStatus,
        updateStatus,
        updateStatusComments,
    };
}
